import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Footer.css';
import logo from '../Logos/LogoElefanteBordesBlancos.png'


const Footer = () => {
  const location = useLocation();
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [isLogosVisible, setIsLogosVisible] = useState(false);

  useEffect(() => {
    const footerTimer = setTimeout(() => {
      setIsFooterVisible(true);
    }, 5500); 

    const logosTimer = setTimeout(() => {
      setIsLogosVisible(true);
    }, 6500); 

    return () => {
      clearTimeout(footerTimer);
      clearTimeout(logosTimer);
    }; 
  }, []);

  const isAdminRoute = location.pathname.startsWith('/admin') || location.pathname.startsWith('/subscriptores') || 
                       location.pathname.startsWith('/editor-masivo') || location.pathname.startsWith('/historial-ventas') || 
                       location.pathname.startsWith('/reporte-de-ventas') || location.pathname.startsWith('/local') || 
                       location.pathname.startsWith('/configuracion-descuentos')  || location.pathname.startsWith('/gestion-stock') ;

  return (
    <footer className={`footer ${isFooterVisible ? 'visible' : 'hidden'}`}>
      {isAdminRoute ? (
        <h1>PRIVISTORE</h1>
      ) : (
        <>
          <div className={`footer-logos-container ${isLogosVisible ? 'visible' : 'hidden'}`}>
          
          </div>
          <div className="footer-contact">
            <img src={logo} alt="Logo" className="home-logo-quinta" />
            <h4>Allegra Club House</h4>
            {/* <p>Bv. Unión </p> */}
            <p>Bell Ville, Córdoba, Argentina.</p>
            {/* <p>Teléfono: +54 9 3537 324169</p> */}
          </div>
          <div className="footer-developer">
          
          </div>
        </>
      )}
    </footer>
  );
};

export default Footer;
