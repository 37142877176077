import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, getDocs, doc, updateDoc, deleteDoc, addDoc } from 'firebase/firestore';
import Notification from './Notification';
import Spinner from './Spinner';
import ProductDetailModal from './ProductDetailModal';
import ProductForm from './ProductForm';

import './Products.css';

const formatNumber = (number) => {
  if (typeof number !== 'number' || isNaN(number)) {
    return '0,00';
  }
  return number
    .toFixed(2) // Mantiene dos decimales
    .replace('.', ',') // Reemplaza el punto decimal por una coma
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agrega puntos como separadores de miles
};

const Products = ({ cart, setCart }) => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [showAvailableOnly, setShowAvailableOnly] = useState(false);
  const [notification, setNotification] = useState({ message: '', show: false });
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editProduct, setEditProduct] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const navigate = useNavigate();

  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  const isAdmin = localStorage.getItem('isAdmin') === 'true';

  const fetchProducts = useCallback(async () => {
    try {
      const productsCollection = await getDocs(collection(db, 'otherProducts'));
      const productsData = productsCollection.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setProducts(productsData);

      const uniqueCategories = [...new Set(productsData.map(product => product.category))];
      setCategories(uniqueCategories);
    } catch (error) {
      console.error("Error fetching products:", error);
      setNotification({ message: 'Error al cargar productos.', show: true });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const addToCart = (product, quantity) => {
    const existingProduct = cart.find(item => item.id === product.id);
    const existingQuantity = existingProduct ? existingProduct.quantity : 0;
  
    if (product.stock < quantity + existingQuantity) {
      setNotification({ message: 'No hay suficiente stock disponible.', show: true });
      return;
    }
  
    const updatedCart = existingProduct
      ? cart.map(item => item.id === product.id ? { ...item, quantity: item.quantity + quantity } : item)
      : [...cart, { ...product, quantity, collection: 'otherProducts' }]; // Specify 'products' collection here
  
    setCart(updatedCart);
    setNotification({ message: `Añadido ${quantity} ${product.model} al carrito.`, show: true });
    setTimeout(() => setNotification({ message: '', show: false }), 1000);
  };
  
  

  const handleAction = (action, product) => {
    if (!isAdmin && (action === 'edit' || action === 'delete')) {
      navigate('/login');
      return;
    }
    if (action === 'edit') {
      setEditProduct(product);
      setIsModalOpen(true);
    } else if (action === 'delete') {
      handleDelete(product);
    } else if (action === 'view') {
      setSelectedProduct(product);
    }
  };

  const handleDelete = async (product) => {
    if (window.confirm("¿Estás seguro de que deseas eliminar este producto?")) {
      try {
        await deleteDoc(doc(db, 'otherProducts', product.id));
        setProducts(products.filter(p => p.id !== product.id));
        setNotification({ message: 'Producto eliminado con éxito.', show: true });
      } catch (error) {
        console.error("Error al eliminar el producto: ", error);
        setNotification({ message: 'Error al eliminar el producto.', show: true });
      } finally {
        setTimeout(() => setNotification({ message: '', show: false }), 3000);
      }
    }
  };

  const handleProductSubmit = async (productData) => {
    setLoading(true);
    try {
      if (editProduct) {
        await updateDoc(doc(db, 'otherProducts', editProduct.id), productData);
        setProducts(products.map(p => p.id === editProduct.id ? { ...productData, id: editProduct.id } : p));
        setNotification({ message: 'Producto actualizado con éxito.', show: true });
      } else {
        const docRef = await addDoc(collection(db, 'otherProducts'), productData);
        setProducts([...products, { ...productData, id: docRef.id }]);
        setNotification({ message: 'Producto agregado con éxito.', show: true });
      }
      setIsModalOpen(false);
      setEditProduct(null);
      fetchProducts();
    } catch (error) {
      console.error("Error al guardar el producto: ", error);
      setNotification({ message: 'Error al guardar el producto.', show: true });
    } finally {
      setLoading(false);
      setTimeout(() => setNotification({ message: '', show: false }), 3000);
    }
  };

  const filteredProducts = products.filter(product =>
    (selectedCategory === 'all' || product.category === selectedCategory) &&
    (!showAvailableOnly || product.stock > 0)
  );

  if (loading) return <Spinner />;

  return (
    <div className="products-container">
      <Notification message={notification.message} show={notification.show} />
      <div className="category-buttons-container">
        <div className="dropdown-container">
          <div className="dropdown-content-container">
            {categories.map(category => (
              <button key={category} onClick={() => { setSelectedCategory(category); setShowAvailableOnly(false); }} className="dropdown-content-button">{category}</button>
            ))}
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal-overlay-container">
          <div className="modal-content-container">
            <ProductForm
              product={editProduct}
              onSubmit={handleProductSubmit}
              onCancel={() => {
                setIsModalOpen(false);
                setEditProduct(null);
              }}
              categories={categories}
              setCategories={setCategories}
            />
          </div>
        </div>
      )}
      {selectedProduct && (
        <ProductDetailModal
          product={selectedProduct}
          onClose={() => setSelectedProduct(null)}
        />
      )}
      <div className="ball-list-container">
        {filteredProducts.map((product) => (
          <ProductItem
            key={product.id}
            product={product}
            addToCart={addToCart}
            isAuthenticated={isAuthenticated}
            isAdmin={isAdmin}
            handleAction={handleAction}
          />
        ))}
      </div>
      {isAdmin && <button onClick={() => setIsModalOpen(true)} className="category-button">Agregar Producto</button>}
    </div>
  );
};

const ProductItem = ({ product, addToCart, isAuthenticated, isAdmin, handleAction }) => {
  const [quantity, setQuantity] = useState(1);

  return (
    <div className={`product-item-container ${product.stock === 0 ? 'out-of-stock' : ''}`}>
      <BallCard
        model={product.model}
        image={product.images ? product.images[0] : ''}
        price={`$${formatNumber(product.price)}`}
        category={product.category}
        onClick={() => handleAction('view', product)} 
      /><br/><br/>
      <div className="product-actions-container">
        <label htmlFor={`quantity-${product.id}`} className="product-action-label">Cantidad:</label>
        <input
          type="number"
          id={`quantity-${product.id}`}
          value={quantity}
          min={1}
          max={product.stock}
          onChange={(e) => setQuantity(Number(e.target.value))}
          className="product-action-input"
        />
        <button className="product-action-button add" onClick={() => addToCart(product, quantity)} disabled={product.stock === 0}>
          {product.stock > 0 ? 'Agregar al Carrito' : 'Sin Stock'}
        </button>
       
        {isAuthenticated && (
          <>
            <button className="product-action-button" onClick={() => handleAction('edit', product)}>Editar</button>
            {isAdmin && <button className="product-action-button delete" onClick={() => handleAction('delete', product)}>Eliminar</button>}
          </>
        )}
      </div>
    </div>
  );
};

const BallCard = ({ model, image, price, onClick }) => { 
  return (
    <div className="ball-card-container" onClick={onClick}> 
      <img src={image} alt={model} className="ball-card-image" />
      <h3 className="ball-card-title">{model}</h3>
      <p className="ball-card-price">{price}</p>
    </div>
  );
};


export default Products;
