import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { addDoc, collection, getDocs, doc, getDoc, runTransaction, updateDoc } from 'firebase/firestore';
import './Cart.css';
import './Receipt.css';
import Spinner from './Spinner';
import Notifications from './Notification';

const formatNumber = (number) => {
  if (typeof number !== 'number' || isNaN(number)) {
    return '0,00';
  }
  return number
    .toFixed(2) // Mantiene dos decimales
    .replace('.', ',') // Reemplaza el punto decimal por una coma
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agrega puntos como separadores de miles
};

const Cart = ({ cart, removeFromCart, clearCart, updateCartItemQuantity }) => {
  const [notification, setNotification] = useState({ message: '', show: false });
  const [formData, setFormData] = useState({
    nombre: '', telefono: '', email: '', domicilio: '', localidad: '',
    codigoPostal: '', provincia: '', pais: '', informacionAdicional: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [discounts, setDiscounts] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [appliedDiscount, setAppliedDiscount] = useState(null);
  const [showDiscounts, setShowDiscounts] = useState(false);
  const [orderData, setOrderData] = useState(null);

  useEffect(() => {
    const fetchDiscounts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'discounts'));
        const fetchedDiscounts = querySnapshot.docs.map(doc => doc.data());
        setDiscounts(fetchedDiscounts);
      } catch (error) {
        console.error("Error fetching discounts:", error);
      }
    };
    fetchDiscounts();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const showNotification = (message) => {
    setNotification({ message, show: true });
    setTimeout(() => setNotification({ message: '', show: false }), 3000);
  };

  const checkStockAvailability = async () => {
    for (const item of cart) {
      const collectionName = item.collection || 'products';
      const productRef = doc(db, collectionName, item.id);
      const productDoc = await getDoc(productRef);
  
      if (!productDoc.exists()) {
        console.error(`El producto con ID ${item.id} no existe en la colección ${collectionName}.`);
        return false;
      }
  
      const productData = productDoc.data();
      if (productData.stock < item.quantity) {
        console.error(`El producto con ID ${item.id} tiene un stock insuficiente. Stock disponible: ${productData.stock}, cantidad solicitada: ${item.quantity}.`);
        return false;
      }
    }
    return true;
  };

  const getOrderNumber = async (type) => {
    const counterRef = doc(db, 'counters', type === 'sale' ? 'saleCounter' : 'orderCounter');
    try {
      const newOrderNumber = await runTransaction(db, async (transaction) => {
        const counterDoc = await transaction.get(counterRef);
        if (!counterDoc.exists()) {
          throw new Error(`${type === 'sale' ? 'Sale' : 'Order'} counter document does not exist!`);
        }
        const newOrderNumber = counterDoc.data().currentOrderNumber + 1;
        transaction.update(counterRef, { currentOrderNumber: newOrderNumber });
        return newOrderNumber;
      });
      return newOrderNumber;
    } catch (error) {
      console.error("Transaction failed: ", error);
      showNotification(`Error generating ${type === 'sale' ? 'sale' : 'order'} number, please try again.`);
      return null;
    }
  };

  const handleCheckoutPro = async () => {
    setIsLoading(true);

    if (!formData.nombre || !formData.email || !formData.telefono || !formData.domicilio) {
        showNotification('Por favor, complete la información de envío antes de proceder al pago.');
        setIsLoading(false);
        return;
    }

    const isStockAvailable = await checkStockAvailability();
    if (!isStockAvailable) {
        showNotification('No hay inventario disponible, elimine items del carrito agotados.');
        setIsLoading(false);
        return;
    }

    const { subtotal, discountAmount, discountDetail, finalPrice } = calculateTotalPrice();

    // Enviar el total con descuento como un solo item
    const items = [{
        title: 'Compra Total',
        unit_price: finalPrice, // Total con el descuento aplicado
        quantity: 1, // Se trata como una compra única
        id: 'total_purchase'
    }];

    const orderNumber = await getOrderNumber('sale');
    if (!orderNumber) {
        setIsLoading(false);
        return;
    }

    const newOrderData = {
        orderNumber,
        ...formData,
        cartItems: cart.map(item => ({
            ...item,
            isUsed: false,
        })),
        subtotal,
        discountAmount,
        discountDetail,
        totalPrice: finalPrice,
        date: new Date(),
        status: 'Procesando',
        isPaid: false,
    };

    try {
        const orderRef = await addDoc(collection(db, 'compras_mercadopago'), { ...newOrderData, isActive: true });

        const response = await fetch('https://us-central1-alegra-club.cloudfunctions.net/createPreference', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                items,
                payer: {
                    name: formData.nombre,
                    email: formData.email,
                    phone: {
                        area_code: '',
                        number: formData.telefono,
                    },
                    address: {
                        zip_code: formData.codigoPostal,
                        street_name: formData.domicilio,
                        street_number: '',
                    }
                },
                external_reference: orderRef.id,
            }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.init_point) {
            localStorage.setItem('pendingOrder', JSON.stringify(newOrderData));
            clearCart();
            setKeyword('');
            setAppliedDiscount(null);
            window.location.href = data.init_point;
        } else {
            showNotification('Error al crear la preferencia de pago, por favor intente nuevamente.');
        }
    } catch (error) {
        console.error('Error al crear la preferencia de pago:', error);
        showNotification('Error al crear la preferencia de pago, por favor intente nuevamente.');
    } finally {
        setIsLoading(false);
    }
};

  useEffect(() => {
    const checkPaymentStatus = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const status = urlParams.get('status');
      const externalReference = urlParams.get('external_reference');

      if (status === 'success' && externalReference) {
        try {
          const orderRef = doc(db, 'compras_mercadopago', externalReference);
          const orderDoc = await getDoc(orderRef);

          if (orderDoc.exists()) {
            const orderData = orderDoc.data();
            if (!orderData.isPaid) {
              await updateDoc(orderRef, { isPaid: true });
              showNotification('Pago recibido!');
            }
            setOrderData(orderData);
          }
        } catch (error) {
          console.error('Error al verificar el estado del pago:', error);
          showNotification('Error al verificar el estado del pago. Por favor, contacta a soporte.');
        }
      }
    };

    checkPaymentStatus();
  }, []);

  const calculateTotalPrice = () => {
    const totalQuantity = cart.reduce((sum, item) => sum + item.quantity, 0);
    const subtotal = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);

    let discountAmount = 0;
    let discountDetail = '';

    if (appliedDiscount && appliedDiscount.type === 'keyword') {
      discountAmount = subtotal * (appliedDiscount.percentage / 100);
      discountDetail = `Descuento aplicado (${appliedDiscount.percentage}%) por palabra clave`;
    } else {
      discounts.forEach(discount => {
        if (discount.type === 'quantity' && totalQuantity >= discount.minQuantity) {
          const currentDiscount = subtotal * (discount.percentage / 100);
          if (currentDiscount > discountAmount) {
            discountAmount = currentDiscount;
            discountDetail = `Descuento aplicado (${discount.percentage}%) por cantidad mínima`;
          }
        } else if (discount.type === 'amount' && subtotal >= discount.minAmount) {
          const currentDiscount = subtotal * (discount.percentage / 100);
          if (currentDiscount > discountAmount) {
            discountAmount = currentDiscount;
            discountDetail = `Descuento aplicado (${discount.percentage}%) por monto mínimo`;
          }
        }
      });
    }

    const finalPrice = subtotal - discountAmount;

    return { subtotal, discountAmount, discountDetail, finalPrice };
  };

  const handleApplyDiscount = () => {
    const foundDiscount = discounts.find(discount => discount.type === 'keyword' && discount.keyword === keyword);
    if (foundDiscount) {
      setAppliedDiscount(foundDiscount);
      showNotification(`Descuento del ${foundDiscount.percentage}% aplicado!`);
    } else {
      showNotification('Código de descuento no válido.');
    }
  };

  const handleQuantityChange = (e, itemId) => {
    const newQuantity = parseInt(e.target.value);
    updateCartItemQuantity(itemId, newQuantity);
  };

  const { subtotal, discountAmount, discountDetail, finalPrice } = calculateTotalPrice();

  return (
    <div className="cart">
      <Notifications message={notification.message} show={notification.show} />
      {isLoading && <Spinner />}
      <h2>Carrito de Compras</h2>
      {cart.length === 0 && !orderData ? (
        <p>El carrito está vacío.</p>
      ) : (
        <div>
          {!orderData && cart.map(item => (
            <div key={item.id} className="cart-item">
              <img src={item.images ? item.images[0] : ''} alt={item.model} className="cart-item-image" />
              <div className="cart-item-details">
                <span>{item.model}</span>
                <span>
                  Cantidad:
                  <input
                    type="number"
                    value={item.quantity}
                    min="1"
                    onChange={(e) => handleQuantityChange(e, item.id)}
                    className="quantity-input"
                  />
                </span>
                <span>Precio: ${formatNumber(item.price)}</span>
                <span>Total: ${formatNumber(item.price * item.quantity)}</span>
              </div>
              <button className='local-sales-remove-btn' onClick={() => removeFromCart(item.id, item.collection)}>Eliminar</button>
            </div>
          ))}
          {!orderData && (
            <div className="cart-total">
              <h3>Subtotal: ${formatNumber(subtotal)}</h3>
              {discountDetail && <h4>{discountDetail}: -${formatNumber(discountAmount)}</h4>}
              <h3>Total: ${formatNumber(finalPrice)}</h3>
              <div className="discount-code">
                <input
                  type="text"
                  placeholder="Código de descuento"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  className="discount-input"
                />
                <br/>
                <button onClick={handleApplyDiscount} className="cart-button">
                  Aplicar Descuento
                </button>
              </div>
              <div className="order-form">
                <h3>Información para Comprar con MercadoPago</h3>
                <form>
                  <input type="text" name="nombre" placeholder="Nombre" value={formData.nombre} onChange={handleChange} maxLength={50} required />
                  <input type="text" name="telefono" placeholder="Teléfono" value={formData.telefono} onChange={handleChange} maxLength={20} required />
                  <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} maxLength={50} required />
                  <input type="text" name="domicilio" placeholder="Domicilio" value={formData.domicilio} onChange={handleChange} maxLength={100} required />
                  <input type="text" name="localidad" placeholder="Localidad" value={formData.localidad} onChange={handleChange} maxLength={50} required />
                  <input type="text" name="codigoPostal" placeholder="Código Postal" value={formData.codigoPostal} onChange={handleChange} maxLength={10} required />
                  <input type="text" name="provincia" placeholder="Provincia" value={formData.provincia} onChange={handleChange} maxLength={50} required />
                  <input type="text" name="pais" placeholder="País" value={formData.pais} onChange={handleChange} maxLength={50} required /> <br/>
                  <textarea name="informacionAdicional" placeholder="Información Adicional" value={formData.informacionAdicional} onChange={handleChange} maxLength={300}></textarea> <br/>
                  <button type="button" className="cart-button" onClick={handleCheckoutPro}>
                    {isLoading ? 'Procesando...' : 'Comprar con MercadoPago'}
                  </button>
                </form>
              </div>
              <button className="cart-button" onClick={() => setShowDiscounts(!showDiscounts)}>
                {showDiscounts ? 'Ocultar Descuentos' : 'Mostrar Descuentos'}
              </button>
              {showDiscounts && (
        <div className="discount-list">
          <h3 className="discount-list-title">Descuentos Disponibles</h3>

          {discounts.map(discount => (
            <div key={discount.id} className="discount-item">
              {discount.minQuantity && discount.minQuantity !== 'N/A' && (
                <span className="discount-detail">Cantidad mínima: {discount.minQuantity} unidades</span>
              )}
              {discount.minAmount && discount.minAmount !== 'N/A' && (
                <span className="discount-detail">Monto mínimo: ${formatNumber(discount.minAmount)}</span>
              )}
              {discount.percentage && discount.percentage !== 'N/A' && (
                <span className="discount-detail">Descuento: -{discount.percentage}%</span>
              )}
              {discount.keyword && discount.keyword !== 'N/A' && (
                <span className="discount-detail">Con código de descuento</span>
              )}
            </div>
          ))}
        </div>
      )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Cart;
