import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import './Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Obtener el rol del usuario desde Firestore
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('userRole', userData.role);
        if (userData.role === 'admin') {
          localStorage.setItem('isAdmin', 'true');
          navigate('/admin');
          window.location.reload(); // Forzar la recarga de la página
        } else if (userData.role === 'cashier') {
          localStorage.setItem('isAdmin', 'false');
          navigate('/local');
          window.location.reload(); // Forzar la recarga de la página
        } else if (userData.role === 'qrScanner') {  // Nuevo rol agregado
          localStorage.setItem('isAdmin', 'false');
          navigate('/escanear-qr');
          window.location.reload(); // Forzar la recarga de la página
        } else {
          setError('No tienes permiso para acceder a esta aplicación.');
        }
      } else {
        setError('No se encontró el rol del usuario.');
      }
    } catch (error) {
      setError('Email o contraseña incorrectos.');
    }
};


  return (
    <div className="login-container">
      <h2 className="login-title">Login</h2>
      <form onSubmit={handleLogin} className="login-form">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="login-input"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="login-input"
        />
        <button type="submit" className="login-button">Login</button>
        {error && <p className="login-error">{error}</p>}
      </form>

      <span>Solo para usuarios autorizados. Si quieres tu propia app, comunicate con el desarrollador a priball20@gmail.com.</span>
    </div>
  );
};

export default Login;
