import React, { useState, useEffect, useCallback } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { db } from '../firebase';
import { collection, query, getDocs, where, orderBy } from 'firebase/firestore';
import './OrderStats.css';
import './Spinner';

const OrderStats = () => {
  const getDefaultStartDate = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1).toISOString().split('T')[0];
  };

  const getDefaultEndDate = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString().split('T')[0];
  };

  const [data, setData] = useState([]);
  const [isUsedDataProducts, setIsUsedDataProducts] = useState([]);
  const [freeTicketModelData, setFreeTicketModelData] = useState([]);
  const [freeTicketModelUsedData, setFreeTicketModelUsedData] = useState([]);
  const [startDate, setStartDate] = useState(getDefaultStartDate());
  const [endDate, setEndDate] = useState(getDefaultEndDate());

  const fetchData = useCallback(async () => {
    if (!startDate || !endDate) return;

    try {
      const q = query(
        collection(db, 'compras_mercadopago'),
        where('date', '>=', new Date(startDate)),
        where('date', '<=', new Date(endDate)),
        orderBy('date', 'asc')
      );

      const querySnapshot = await getDocs(q);
      const purchases = querySnapshot.docs.map(doc => doc.data());

      const modelCounts = {};
      const modelPrices = {};
      const usedModelCounts = {};
      const notUsedModelCounts = {};

      purchases.forEach(purchase => {
        if (!purchase.cartItems) return;
        purchase.cartItems.forEach(item => {
          if (!item.model || !item.quantity || !item.price || !item.collection) return;

          // Sumar cantidades por modelo
          modelCounts[item.model] = (modelCounts[item.model] || 0) + item.quantity;

          // Sumar precios por modelo
          modelPrices[item.model] = (modelPrices[item.model] || 0) + item.quantity * item.price;

          // Contar productos usados y no usados por modelo en la colección 'products'
          if (item.collection === 'products') {
            if (item.isUsed) {
              usedModelCounts[item.model] = (usedModelCounts[item.model] || 0) + item.quantity;
            } else {
              notUsedModelCounts[item.model] = (notUsedModelCounts[item.model] || 0) + item.quantity;
            }
          }
        });
      });

      const quantityChartData = Object.keys(modelCounts).map(model => ({
        name: model,
        value: modelCounts[model],
      }));

      const priceChartData = Object.keys(modelPrices).map(model => ({
        name: model,
        value: modelPrices[model],
      }));

      const usedDataProducts = Object.keys(usedModelCounts).map(model => ({
        name: `${model} (Usado)`,
        value: usedModelCounts[model],
      }));

      const notUsedDataProducts = Object.keys(notUsedModelCounts).map(model => ({
        name: `${model} (No Usado)`,
        value: notUsedModelCounts[model],
      }));

      setData({ quantityChartData, priceChartData });
      setIsUsedDataProducts([...usedDataProducts, ...notUsedDataProducts]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [startDate, endDate]);

  const fetchFreeTicketData = useCallback(async () => {
    try {
      const q = query(
        collection(db, 'FreeTicket'),
        where('createdAt', '>=', new Date(startDate)),
        where('createdAt', '<=', new Date(endDate))
      );

      const querySnapshot = await getDocs(q);
      const freeTickets = querySnapshot.docs.map(doc => doc.data());

      const modelCreatedCounts = {};
      const modelUsedCounts = {};

      freeTickets.forEach(ticket => {
        ticket.cartItems.forEach(item => {
          // Contar tickets creados por modelo
          modelCreatedCounts[item.model] = (modelCreatedCounts[item.model] || 0) + item.quantity;

          // Contar tickets usados por modelo
          if (item.isUsed) {
            modelUsedCounts[item.model] = (modelUsedCounts[item.model] || 0) + item.quantity;
          }
        });
      });

      const freeTicketModelData = Object.keys(modelCreatedCounts).map(model => ({
        name: model,
        value: modelCreatedCounts[model],
      }));

      const freeTicketModelUsedData = Object.keys(modelUsedCounts).map(model => ({
        name: model,
        value: modelUsedCounts[model],
      }));

      setFreeTicketModelData(freeTicketModelData);
      setFreeTicketModelUsedData(freeTicketModelUsedData);
    } catch (error) {
      console.error('Error fetching free ticket data:', error);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    fetchData();
    fetchFreeTicketData();
  }, [fetchData, fetchFreeTicketData]);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];

  const renderLegendText = (value, entry) => {
    const { name, value: count } = entry.payload;
    return `${name}: ${count}`;
  };

  const renderTotal = (data) => {
    const total = data.reduce((acc, entry) => acc + entry.value, 0);
    return <p className="total-values">Total: {total}</p>;
  };

  return (
    <div className="orderStats-container">
      <h3>Estadísticas de Ventas</h3>
      <div className="orderStats-filters">
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          placeholder="Fecha de inicio"
          className="orderStats-filterInput"
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          placeholder="Fecha de fin"
          className="orderStats-filterInput"
        />
      </div>
      {data && data.quantityChartData && data.priceChartData ? (
        <div className="orderStats-charts">
          <div className="orderStats-chart">
            <h4>Cantidad por Producto</h4>
            <PieChart width={400} height={400}>
              <Pie
                data={data.quantityChartData}
                cx={200}
                cy={200}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
                label={false}
              >
                {data.quantityChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend formatter={renderLegendText} />
            </PieChart>
            {renderTotal(data.quantityChartData)}
          </div>
          <div className="orderStats-chart">
            <h4>Monto Producto</h4>
            <PieChart width={400} height={400}>
              <Pie
                data={data.priceChartData}
                cx={200}
                cy={200}
                outerRadius={100}
                fill="#82ca9d"
                dataKey="value"
                label={false}
              >
                {data.priceChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend formatter={renderLegendText} />
            </PieChart>
            {renderTotal(data.priceChartData)}
          </div>
          <div className="orderStats-chart">
            <h4>Entradas</h4>
            <PieChart width={400} height={400}>
              <Pie
                data={isUsedDataProducts}
                cx={200}
                cy={200}
                outerRadius={100}
                fill="#ff7f50"
                dataKey="value"
                label={false}
              >
                {isUsedDataProducts.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend formatter={renderLegendText} />
            </PieChart>
            {renderTotal(isUsedDataProducts)}
          </div>
          <div className="orderStats-chart">
            <h4>Tickets Gratuitos - Modelos Creados</h4>
            <PieChart width={400} height={400}>
              <Pie
                data={freeTicketModelData}
                cx={200}
                cy={200}
                outerRadius={100}
                fill="#ff7f50"
                dataKey="value"
                label={false}
              >
                {freeTicketModelData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend formatter={renderLegendText} />
            </PieChart>
            {renderTotal(freeTicketModelData)}
          </div>
          <div className="orderStats-chart">
            <h4>Tickets Gratuitos - Modelos Usados</h4>
            <PieChart width={400} height={400}>
              <Pie
                data={freeTicketModelUsedData}
                cx={200}
                cy={200}
                outerRadius={100}
                fill="#82ca9d"
                dataKey="value"
                label={false}
              >
                {freeTicketModelUsedData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend formatter={renderLegendText} />
            </PieChart>
            {renderTotal(freeTicketModelUsedData)}
          </div>
        </div>
      ) : (
        <p>Cargando datos...</p>
      )}
    </div>
  );
};

export default OrderStats;
