import React, { useRef, useEffect, useState, useCallback } from 'react';
import jsQR from 'jsqr';
import { db } from '../firebase';
import { collection, query, getDocs, doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import './QRScanner.css';

const QRScanner = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [qrData, setQRData] = useState(null);
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false); // Cambiado para manejar ambas acciones
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [isScannerOn, setIsScannerOn] = useState(true); // Inicialmente el escáner está encendido

  const startCamera = useCallback(async () => {
    console.log('Iniciando cámara...');
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ 
        video: { facingMode: 'environment' } 
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = () => {
          videoRef.current.play();
          setIsCameraActive(true);
          console.log('Cámara iniciada con éxito');
        };
      }
      return stream;
    } catch (err) {
      console.error('Error al acceder a la cámara:', err);
      setError('Error al acceder a la cámara: ' + err.message);
      setIsCameraActive(false);
    }
  }, []);

  const stopCamera = useCallback(() => {
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach(track => track.stop());
      setIsCameraActive(false);
      console.log('Cámara detenida');
    }
  }, []);

  useEffect(() => {
    let stream;
    if (isScannerOn) {
      startCamera().then(s => {
        stream = s;
      });
    }
  
    return () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
        setIsCameraActive(false);
        console.log('Cámara detenida');
      }
    };
  }, [startCamera, isScannerOn]);

  const toggleScanner = () => {
    setIsScannerOn(prevState => !prevState);
    if (isScannerOn) {
      stopCamera();
    } else {
      startCamera();
    }
  };

  const fetchQRData = useCallback(async (qrValue) => {
    console.log('Obteniendo datos del QR:', qrValue);
    try {
      const collectionsToSearch = ['compras_mercadopago', 'FreeTicket'];

      let foundItem = null;
      let docId = null;
      let collectionName = null;

      const processDoc = (doc, collectionNameToSearch) => {
        const data = doc.data();
        console.log('Revisando documento:', doc.id, 'Datos:', data);
        if (data.cartItems && Array.isArray(data.cartItems)) {
          const item = data.cartItems.find(item => item.qrValue === qrValue);
          if (item) {
            foundItem = { 
              ...item, 
              orderNumber: data.orderNumber, 
              nombre: data.nombre,
              cartItems: data.cartItems 
            };
            docId = doc.id;
            collectionName = collectionNameToSearch;
            console.log('Encontrado item coincidente en documento:', doc.id, 'Item:', foundItem);
          }
        }
      };

      for (const collectionNameToSearch of collectionsToSearch) {
        const ref = collection(db, collectionNameToSearch);
        const q = query(ref);
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => processDoc(doc, collectionNameToSearch));

        if (foundItem) break;
      }

      if (foundItem) {
        console.log('Estableciendo datos del QR:', { ...foundItem, docId, collectionName });
        setQRData({ ...foundItem, docId, collectionName });
        setError(null);
        // No detenemos la cámara aquí
      } else {
        console.log('Código QR no encontrado en la base de datos');
        setError('El código QR no se encuentra en la base de datos');
        setQRData(null);
      }
    } catch (err) {
      console.error('Error al obtener datos del QR:', err);
      setError(`Error al obtener datos del QR: ${err.message}`);
      setQRData(null);
    }
  }, []);

  const scanQRCode = useCallback(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (video && canvas && video.readyState === video.HAVE_ENOUGH_DATA) {
      canvas.height = video.videoHeight;
      canvas.width = video.videoWidth;
      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const code = jsQR(imageData.data, imageData.width, imageData.height);

      if (code) {
        console.log('Código QR detectado:', code.data);
        fetchQRData(code.data);
      }
    }

    if (!qrData) {
      requestAnimationFrame(scanQRCode);
    }
  }, [fetchQRData, qrData]);

  useEffect(() => {
    let animationFrameId;
    if (isCameraActive) {
      console.log('Iniciando escaneo de QR');
      animationFrameId = requestAnimationFrame(scanQRCode);
    }
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [scanQRCode, isCameraActive]);

  const handleMarkUse = async () => {
    console.log('Intentando marcar como usado. Datos del QR:', qrData);
    if (!qrData || !qrData.docId || !qrData.qrValue || !qrData.cartItems || !Array.isArray(qrData.cartItems)) {
      console.error('Datos del QR inválidos');
      setError('No se puede marcar el uso: datos del QR no válidos');
      return;
    }
  
    setIsProcessing(true);
  
    try {
      const docRef = doc(db, qrData.collectionName, qrData.docId);
      const localTimestamp = new Date().toLocaleString('es-AR', { timeZone: 'America/Argentina/Buenos_Aires' });
      
      const updatedCartItems = qrData.cartItems.map(item =>
        item.qrValue === qrData.qrValue 
          ? { ...item, isUsed: true, usedTimestamp: localTimestamp } 
          : item
      );
  
      console.log('Actualizando documento con ID:', qrData.docId);
      console.log('Items actualizados del carrito:', updatedCartItems);
  
      await updateDoc(docRef, { 
        cartItems: updatedCartItems,
        lastUpdated: serverTimestamp() 
      });
  
      console.log('Código QR marcado como usado con timestamp:', localTimestamp);
      setQRData(null); // Cerrar la modal y reanudar el escaneo
    } catch (err) {
      console.error('Error al actualizar el estado isUsed:', err);
      setError(`Error al actualizar el estado: ${err.message}`);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUnmarkUse = async () => {
    console.log('Intentando desmarcar como usado. Datos del QR:', qrData);
    if (!qrData || !qrData.docId || !qrData.qrValue || !qrData.cartItems || !Array.isArray(qrData.cartItems)) {
      console.error('Datos del QR inválidos');
      setError('No se puede desmarcar el uso: datos del QR no válidos');
      return;
    }
  
    setIsProcessing(true);
  
    try {
      const docRef = doc(db, qrData.collectionName, qrData.docId);
      
      const updatedCartItems = qrData.cartItems.map(item =>
        item.qrValue === qrData.qrValue 
          ? { ...item, isUsed: false, usedTimestamp: null } 
          : item
      );
  
      console.log('Actualizando documento con ID:', qrData.docId);
      console.log('Items actualizados del carrito:', updatedCartItems);
  
      await updateDoc(docRef, { 
        cartItems: updatedCartItems,
        lastUpdated: serverTimestamp() 
      });
  
      console.log('Código QR desmarcado como usado');
      setQRData(null); // Cerrar la modal y reanudar el escaneo
    } catch (err) {
      console.error('Error al actualizar el estado isUsed:', err);
      setError(`Error al actualizar el estado: ${err.message}`);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCancelUse = () => {
    setQRData(null);
    setError(null);
    console.log('Reiniciando escaneo de QR');
    // No es necesario reiniciar la cámara, ya que nunca la detenemos
  };

  const closeModal = () => {
    setQRData(null);
    setError(null);
    console.log('Modal cerrado, reanudando escaneo');
    // El escaneo se reanuda automáticamente porque qrData es null
  };

  return (
    <div className="qr-scanner-container">
      <h2 className="qr-scanner-title">Escáner de QR</h2>
      
      <div className="qr-scanner-view">
        <video ref={videoRef} autoPlay playsInline style={{ display: 'none' }} />
        <canvas ref={canvasRef} />
        {!isCameraActive && <div className="qr-scanner-loading">Scanner apagado</div>}
        {error && <div className="qr-scanner-error">{error}</div>}
      </div>
      {qrData && (
        <div className="qr-modal-overlay" onClick={closeModal}>
          <div className={`qr-modal ${qrData.isUsed ? 'used' : 'active'}`} onClick={(e) => e.stopPropagation()}>
            <h3 className="qr-modal-title">Información del QR</h3>
            <div className="qr-modal-content">
              <p>Cantidad: {qrData.quantity}</p>
              <p>{qrData.model}</p>
              <p>Nombre: {qrData.nombre}</p>
              <p>Número de ticket: {qrData.orderNumber}</p>
              <p>Estado: {qrData.isUsed ? 'Usado' : 'Vigente'}</p>
              {qrData.usedTimestamp && (
                <p>Usado el: {qrData.usedTimestamp}</p>
              )}
            </div>
            <div className="qr-modal-actions">
              {!qrData.isUsed ? (
                <button
                  className="qr-button mark"
                  onClick={handleMarkUse}
                  disabled={isProcessing}
                >
                  {isProcessing ? 'Procesando...' : 'Marcar'}
                </button>
              ) : (
                <button
                  className="qr-button unmark"
                  onClick={handleUnmarkUse}
                  disabled={isProcessing}
                >
                  {isProcessing ? 'Procesando...' : 'Desmarcar'}
                </button>
              )}
              <button
                className="qr-button cancel"
                onClick={handleCancelUse}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      )}
      <br/>
      <div className="scanner-toggle">
        <label className="switch">
          <input type="checkbox" checked={isScannerOn} onChange={toggleScanner} />
          <span className="slider round"></span>
        </label>
      </div>
      <br/>
      <span>{isScannerOn ? 'Escáner encendido' : 'Escáner apagado'}</span>
    </div>
  );
};

export default QRScanner;
