import React, { useState, useEffect } from 'react';
import { ComposedChart, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Bar, Line } from 'recharts';
import { db } from '../firebase';
import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import './WallStreetChart.css';

const WallStreetChart = () => {
  const [chartData, setChartData] = useState([]);
  const [comprasData, setComprasData] = useState([]);
  const [freeTicketData, setFreeTicketData] = useState([]);
  const [modelsSet, setModelsSet] = useState(new Set());
  const [selectedModel, setSelectedModel] = useState(null); 
  const [startDateTime, setStartDateTime] = useState(getDefaultStartDateTime());
  const [endDateTime, setEndDateTime] = useState(getDefaultEndDateTime());
  const [loading, setLoading] = useState(true);
  const [dataZoom, setDataZoom] = useState([0, 100]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const comprasQuery = query(
          collection(db, 'compras_mercadopago'),
          orderBy('date', 'asc')
        );

        const freeTicketQuery = query(
          collection(db, 'FreeTicket'),
          orderBy('createdAt', 'asc')
        );

        const [comprasSnapshot, freeTicketSnapshot] = await Promise.all([
          getDocs(comprasQuery),
          getDocs(freeTicketQuery)
        ]);

        const usageData = {};
        const comprasUsageData = {};
        const freeTicketUsageData = {};
        const models = new Set();

        const processSnapshot = (snapshot, usageDataTarget) => {
          snapshot.forEach(doc => {
            const data = doc.data();
            data.cartItems.forEach(item => {
              const model = item.model;
              const itemTimestamp = item.usedTimestamp;

              if (item.isUsed && itemTimestamp && isWithinDateTimeRange(itemTimestamp, startDateTime, endDateTime)) {
                const timeSlot = getTimeSlot(itemTimestamp);

                if (!usageDataTarget[timeSlot]) {
                  usageDataTarget[timeSlot] = { time: timeSlot };
                }

                if (!usageDataTarget[timeSlot][model]) {
                  usageDataTarget[timeSlot][model] = 0;
                }

                usageDataTarget[timeSlot][model] += 1;
                models.add(model);
              }
            });
          });
        };

        // Procesar ambas colecciones
        processSnapshot(comprasSnapshot, usageData);
        processSnapshot(comprasSnapshot, comprasUsageData);
        processSnapshot(freeTicketSnapshot, usageData);
        processSnapshot(freeTicketSnapshot, freeTicketUsageData);

        // Acumular los valores a lo largo del tiempo
        const accumulateData = (usageData, runningTotals, accumulatedData) => {
          Object.keys(usageData).sort().forEach(time => {
            const entry = { time };
            models.forEach(model => {
              runningTotals[model] = (runningTotals[model] || 0) + (usageData[time][model] || 0);
              entry[model] = runningTotals[model];
            });
            accumulatedData.push(entry);
          });
        };

        const accumulatedData = [];
        const accumulatedComprasData = [];
        const accumulatedFreeTicketData = [];
        const runningTotals = {};
        const runningComprasTotals = {};
        const runningFreeTicketTotals = {};

        accumulateData(usageData, runningTotals, accumulatedData);
        accumulateData(comprasUsageData, runningComprasTotals, accumulatedComprasData);
        accumulateData(freeTicketUsageData, runningFreeTicketTotals, accumulatedFreeTicketData);

        setChartData(accumulatedData);
        setComprasData(accumulatedComprasData);
        setFreeTicketData(accumulatedFreeTicketData);
        setModelsSet(models);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching QR usage data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [startDateTime, endDateTime]);

  const handleLegendClick = (model) => {
    setSelectedModel(model === selectedModel ? null : model);
  };

  return (
    <div className="wallStreetChart-container">
      <h3>Crecimiento de Entradas por Intervalos de Tiempo y Modelo</h3>
      <div className="wallStreetChart-filters">
        <input
          type="datetime-local"
          value={startDateTime}
          onChange={(e) => setStartDateTime(e.target.value)}
          placeholder="Fecha y hora de inicio"
          className="wallStreetChart-filterInput"
        />
        <input
          type="datetime-local"
          value={endDateTime}
          onChange={(e) => setEndDateTime(e.target.value)}
          placeholder="Fecha y hora de fin"
          className="wallStreetChart-filterInput"
        />
      </div>
      {loading ? (
        <p>Cargando datos...</p>
      ) : (
        <>
          <h4>Gráfico Combinado (Compras y FreeTicket)</h4>
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart data={chartData.slice(dataZoom[0], dataZoom[1])}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" padding={{ left: 20, right: 20 }} />
              <YAxis />
              {Array.from(modelsSet).map((model, index) => (
                <React.Fragment key={model}>
                  <Bar
                    dataKey={model}
                    fill={getColorForModel(index)}
                    barSize={5}
                    onClick={() => handleLegendClick(model)}
                  />
                  {selectedModel === model && (
                    <Line
                      type="monotone"
                      dataKey={model}
                      stroke={getColorForModel(index)}
                      dot={false}
                    />
                  )}
                </React.Fragment>
              ))}
            </ComposedChart>
          </ResponsiveContainer>
          {/* Leyenda de modelos */}
          <div className="model-legend">
            {Array.from(modelsSet).map((model, index) => (
              <div
                key={model}
                className="model-legend-item"
                onClick={() => handleLegendClick(model)}
                style={{ cursor: 'pointer' }} // Añadir cursor de puntero para indicar que es clicable
              >
                <div
                  className="model-legend-color"
                  style={{ backgroundColor: getColorForModel(index) }}
                ></div>
                <span>{model}</span>
              </div>
            ))}
          </div>

          <h4>Compras MercadoPago</h4>
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart data={comprasData.slice(dataZoom[0], dataZoom[1])}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" padding={{ left: 20, right: 20 }} />
              <YAxis />
              {Array.from(modelsSet).map((model, index) => (
                <React.Fragment key={model}>
                  <Bar
                    dataKey={model}
                    fill={getColorForModel(index)}
                    barSize={5}
                    onClick={() => handleLegendClick(model)}
                  />
                  {selectedModel === model && (
                    <Line
                      type="monotone"
                      dataKey={model}
                      stroke={getColorForModel(index)}
                      dot={false}
                    />
                  )}
                </React.Fragment>
              ))}
            </ComposedChart>
          </ResponsiveContainer>
          {/* Leyenda de modelos */}
          <div className="model-legend">
            {Array.from(modelsSet).map((model, index) => (
              <div
                key={model}
                className="model-legend-item"
                onClick={() => handleLegendClick(model)}
                style={{ cursor: 'pointer' }} // Añadir cursor de puntero para indicar que es clicable
              >
                <div
                  className="model-legend-color"
                  style={{ backgroundColor: getColorForModel(index) }}
                ></div>
                <span>{model}</span>
              </div>
            ))}
          </div>

          <h4>FreeTicket</h4>
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart data={freeTicketData.slice(dataZoom[0], dataZoom[1])}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" padding={{ left: 20, right: 20 }} />
              <YAxis />
              {Array.from(modelsSet).map((model, index) => (
                <React.Fragment key={model}>
                  <Bar
                    dataKey={model}
                    fill={getColorForModel(index)}
                    barSize={5}
                    onClick={() => handleLegendClick(model)}
                  />
                  {selectedModel === model && (
                    <Line
                      type="monotone"
                      dataKey={model}
                      stroke={getColorForModel(index)}
                      dot={false}
                    />
                  )}
                </React.Fragment>
              ))}
            </ComposedChart>
          </ResponsiveContainer>
          {/* Leyenda de modelos */}
          <div className="model-legend">
            {Array.from(modelsSet).map((model, index) => (
              <div
                key={model}
                className="model-legend-item"
                onClick={() => handleLegendClick(model)}
                style={{ cursor: 'pointer' }} // Añadir cursor de puntero para indicar que es clicable
              >
                <div
                  className="model-legend-color"
                  style={{ backgroundColor: getColorForModel(index) }}
                ></div>
                <span>{model}</span>
              </div>
            ))}
          </div>

          <div className="wallStreetChart-zoom">
            <label>Zoom: </label>
            <input
              type="range"
              min="0"
              max={chartData.length}
              value={dataZoom[0]}
              onChange={(e) => setDataZoom([+e.target.value, dataZoom[1]])}
            />
            <input
              type="range"
              min="0"
              max={chartData.length}
              value={dataZoom[1]}
              onChange={(e) => setDataZoom([dataZoom[0], +e.target.value])}
            />
          </div>
        </>
      )}
    </div>
  );
};

// Función para obtener la fecha y hora de inicio predeterminada (el día actual a la 01:00)
const getDefaultStartDateTime = () => {
  const now = new Date();
  return `${now.toISOString().split('T')[0]}T20:00`;
};

// Función para obtener la fecha y hora de fin predeterminada (el día actual a las 05:00)
const getDefaultEndDateTime = () => {
  const now = new Date();
  return `${now.toISOString().split('T')[0]}T08:00`;
};

// Función para verificar si un timestamp está dentro del rango de fecha y hora especificado
const isWithinDateTimeRange = (timestamp, startDateTime, endDateTime) => {
  if (typeof timestamp !== 'string' || !timestamp.includes(", ")) {
    console.error('Invalid timestamp format:', timestamp);
    return false;
  }

  const [datePart, timePart] = timestamp.split(", ");
  if (!datePart || !timePart) {
    console.error('Invalid timestamp split:', timestamp);
    return false;
  }

  const [day, month, year] = datePart.split("/");
  const dateTime = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${timePart}`;
  return dateTime >= startDateTime && dateTime <= endDateTime;
};

// Función para obtener el intervalo de tiempo (por minuto)
const getTimeSlot = (timestamp) => {
  if (typeof timestamp !== 'string' || !timestamp.includes(", ")) {
    console.error('Invalid timestamp format:', timestamp);
    return '';
  }

  const timePart = timestamp.split(", ")[1];
  const [hour, minute] = timePart.split(":");
  return `${hour}:${minute}`;
};

// Función para asignar un color único a cada modelo
const getColorForModel = (index) => {
  const colors = ['#387908', '#ff0000', '#0000ff', '#ff7300', '#8884d8', '#82ca9d'];
  return colors[index % colors.length];
};

export default WallStreetChart;
