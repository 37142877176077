import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, addDoc, runTransaction, doc } from 'firebase/firestore';
import { QRCode } from 'react-qr-code';
import './FreeTicketCreator.css';

const FreeTicketCreator = () => {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [qrData, setQrData] = useState([]);
  const [name, setName] = useState('');

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsSnapshot = await getDocs(collection(db, 'products'));
        const otherProductsSnapshot = await getDocs(collection(db, 'otherProducts'));

        const allProducts = [
          ...productsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })),
          ...otherProductsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })),
        ];

        setProducts(allProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  const createSHA256Hash = async (input) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(input);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  };

  const getOrderNumber = async () => {
    const counterRef = doc(db, 'counters', 'freeCounter');
    try {
      const newOrderNumber = await runTransaction(db, async (transaction) => {
        const counterDoc = await transaction.get(counterRef);
        if (!counterDoc.exists()) {
          throw new Error('¡El documento del contador no existe!');
        }
        const newOrderNumber = counterDoc.data().currentOrderNumber + 1;
        transaction.update(counterRef, { currentOrderNumber: newOrderNumber });
        return newOrderNumber;
      });
      return newOrderNumber;
    } catch (error) {
      console.error("La transacción falló: ", error);
      return null;
    }
  };

  const handleCreateFreeTickets = async () => {
    if (selectedProducts.length === 0) {
      setSuccessMessage('Por favor, selecciona al menos un producto.');
      return;
    }

    setIsLoading(true);
    setSuccessMessage('');
    setQrData([]);

    try {
      const orderNumber = await getOrderNumber();
      if (!orderNumber) {
        setSuccessMessage('Fallo al generar el número de orden. Por favor intenta de nuevo.');
        setIsLoading(false);
        return;
      }

      const cartItems = await Promise.all(
        selectedProducts.map(async (product) => {
          const qrValue = await createSHA256Hash(`${orderNumber}-${product.id}`);
          return {
            model: product.model,
            quantity: product.quantity,
            qrValue,
            isUsed: false,
            nombre: name || 'Invitado', // Asociar nombre con cada item
          };
        })
      );

      const newTicket = {
        orderNumber,
        cartItems,
        createdAt: new Date(),
        emailSent: false,
        nombre: name || 'Invitado', // Agregar nombre al documento principal
      };

      await addDoc(collection(db, 'FreeTicket'), newTicket);

      setQrData(cartItems); // Guardar los datos generados del QR para mostrar
      setSuccessMessage('¡Tickets creados con éxito!');
      setSelectedProducts([]);
      setName('');
    } catch (error) {
      console.error('Error al crear los tickets gratuitos:', error);
      setSuccessMessage('Hubo un problema al crear los tickets.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddToCart = (productId) => {
    const product = products.find(p => p.id === productId);
    if (product && !selectedProducts.find(p => p.id === productId)) {
      setSelectedProducts([...selectedProducts, { ...product, quantity: 1 }]);
    }
  };

  const handleRemoveFromCart = (productId) => {
    setSelectedProducts(selectedProducts.filter(product => product.id !== productId));
  };

  const handleQuantityChange = (productId, quantity) => {
    setSelectedProducts(
      selectedProducts.map(product =>
        product.id === productId ? { ...product, quantity: quantity } : product
      )
    );
  };

  return (
    <div className="freeTicketCreator-container">
      <h2>Crear Ticket Gratuito</h2>
      <div className="product-selection">
        <select
          onChange={(e) => handleAddToCart(e.target.value)}
          className="freeTicketCreator-input"
        >
          <option value="">Seleccionar Producto</option>
          {products.map(product => (
            <option key={product.id} value={product.id}>
              {product.model}
            </option>
          ))}
        </select>
      </div>
      <div className="cart-items">
        <h3>Productos Seleccionados</h3>
        {selectedProducts.length === 0 && <p>No se han seleccionado productos.</p>}
        {selectedProducts.map(product => (
          <div key={product.id} className="cart-item">
            <span>{product.model}</span>
            <input
              type="number"
              value={product.quantity}
              min="1"
              onChange={(e) => handleQuantityChange(product.id, parseInt(e.target.value))}
              className="quantity-input"
            />
            <button onClick={() => handleRemoveFromCart(product.id)}>Eliminar</button>
          </div>
        ))}
      </div>
      <div className="name-input-container">
        <label htmlFor="name">Nombre (opcional):</label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="freeTicketCreator-input"
          placeholder="Escribe un nombre"
        />
      </div>
      <button onClick={handleCreateFreeTickets} className="freeTicketCreator-button" disabled={isLoading}>
        {isLoading ? 'Creando...' : 'Crear Tickets Gratuitos'}
      </button>
      {successMessage && <p className="freeTicketCreator-success">{successMessage}</p>}
      {qrData.length > 0 && (
        <div className="qr-code-preview">
          <h3>QR Codes Generados</h3>
          {qrData.map((product, index) => (
            <div key={index} className="qr-code-item">
              <div className="qr-code-container">
                <QRCode value={product.qrValue} size={256} />
              </div>
              <p>{product.nombre}</p>
              <p>{product.model}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FreeTicketCreator;
