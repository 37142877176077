// pinata.js
import axios from 'axios';

const PINATA_API_KEY = '9bc5696b3255b5b7dc1c';
const PINATA_SECRET_API_KEY = '315490e61fbf56b719a81664d3c142662dd38c96c44191385e93061cb031ea25';

const pinFileToIPFS = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('pinataOptions', JSON.stringify({ cidVersion: 1 }));
  formData.append('pinataMetadata', JSON.stringify({ name: file.name }));

  try {
    const response = await axios.post('https://api.pinata.cloud/pinning/pinFileToIPFS', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        pinata_api_key: PINATA_API_KEY,
        pinata_secret_api_key: PINATA_SECRET_API_KEY
      }
    });
    return `https://gateway.pinata.cloud/ipfs/${response.data.IpfsHash}`;
  } catch (error) {
    console.error('Error uploading image to Pinata:', error);
    throw new Error('Error uploading image, please try again.');
  }
};

export { pinFileToIPFS };
