import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { collection, query, getDocs } from 'firebase/firestore';
import { QRCode } from 'react-qr-code';
import './QRCodePage.css';

const QRCodePage = () => {
  const { qrValue } = useParams();
  const [qrData, setQRData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQRData = async () => {
      console.log('Fetching QR data for:', qrValue);

      try {
        const collectionsToSearch = ['compras_mercadopago', 'FreeTicket'];
        let foundItem = null;
        let orderNumber = null;
        let nombre = null;

        const checkDocuments = (doc, collectionName) => {
          const data = doc.data();
          if (data.cartItems && Array.isArray(data.cartItems)) {
            const item = data.cartItems.find(item => item.qrValue === qrValue);
            if (item) {
              foundItem = item;
              orderNumber = data.orderNumber;
              nombre = data.nombre; // Get the name from the main document
            }
          }
        };

        for (const collectionName of collectionsToSearch) {
          const ref = collection(db, collectionName);
          const q = query(ref);
          const querySnapshot = await getDocs(q);

          querySnapshot.forEach((doc) => checkDocuments(doc, collectionName));

          if (foundItem) break; // Stop searching if we've found the item
        }

        if (foundItem) {
          setQRData({ ...foundItem, orderNumber, nombre });
        } else {
          console.log('No matching documents.');
          setError('QR code not found');
        }
      } catch (err) {
        console.error('Error fetching QR data:', err);
        setError(`Error fetching QR data: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    if (qrValue) {
      fetchQRData();
    } else {
      console.log('No qrValue provided');
      setError('No QR code value provided');
      setLoading(false);
    }
  }, [qrValue]);

  console.log('Render state:', { loading, error, qrData });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!qrData) {
    return <div>No QR data found for value: {qrValue}</div>;
  }

  return (
    <div className="qr-code-page">
      <h2>QR Code Information</h2>
      <div className="qr-code-container">
        <QRCode value={qrData.qrValue} size={256} />
      </div>
      <div className="qr-info">
        <p>Cantidad: {qrData.quantity}</p>
        <p>{qrData.model}</p>
        <p>Nombre: {qrData.nombre}</p>
        <p>Número de ticket: {qrData.orderNumber}</p>
        <p>Estado: {qrData.isUsed ? 'Usado' : 'Vigente'}</p>
        <p>{qrData.usedTimestamp}</p>
      </div>
    </div>
  );
};

export default QRCodePage;
