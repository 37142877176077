import React, { useState, useEffect } from 'react';
import logo from '../Logos/LogoLaQuinta.png'; 
import instagramLogo from '../Logos/logoInstragram.png';
// import facebookLogo from '../Logos/logofacebook.png';
import './Home.css';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Spinner from './Spinner';
import { Link } from 'react-router-dom';
import { pinFileToIPFS } from '../pinata';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const Home = () => {
  const [content, setContent] = useState({
    title: 'Bienvenido a Nuestra Empresa',
    images: [],
    instagram: '',
    facebook: ''
  });
  const [imagePreviews, setImagePreviews] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [copySuccess, setCopySuccess] = useState(''); 

  useEffect(() => {
    const isAdminUser = localStorage.getItem('isAdmin') === 'true';
    setIsAdmin(isAdminUser);

    const fetchHomeInfo = async () => {
      setIsLoading(true);
      try {
        const docRef = doc(db, 'HomeInfo', 'main');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setContent(data);
          setImagePreviews(data.images);
        }
      } catch (error) {
        console.error('Error fetching home info:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchHomeInfo();
  }, []);

  useEffect(() => {
    const handleReCaptchaVerify = async () => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }

      try {
        const token = await executeRecaptcha('subscribe');
        setRecaptchaToken(token);
      } catch (error) {
        console.error('Error executing reCAPTCHA', error);
      }
    };

    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  const handleImageUpload = async (index, e) => {
    const file = e.target.files[0];
    if (file) {
      setIsLoading(true);
      try {
        const imageUrl = await pinFileToIPFS(file);
        const newImages = [...content.images];
        newImages[index] = imageUrl;
        setContent({ ...content, images: newImages });
        const newImagePreviews = [...imagePreviews];
        newImagePreviews[index] = URL.createObjectURL(file);
        setImagePreviews(newImagePreviews);
      } catch (error) {
        console.error('Error uploading image:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleAddImage = () => {
    setContent({ ...content, images: [...content.images, ''] });
    setImagePreviews([...imagePreviews, '']);
  };

  const handleRemoveImage = (index) => {
    const newImages = content.images.filter((_, i) => i !== index);
    const newImagePreviews = imagePreviews.filter((_, i) => i !== index);
    setContent({ ...content, images: newImages });
    setImagePreviews(newImagePreviews);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContent({ ...content, [name]: value });
  };

  const handleSaveChanges = async () => {
    setIsLoading(true);
    try {
      await setDoc(doc(db, 'HomeInfo', 'main'), content);
      alert('Cambios guardados correctamente.');
    } catch (error) {
      console.error('Error saving changes:', error);
      alert('Error al guardar los cambios, por favor intente nuevamente.');
    } finally {
      setIsLoading(false);
    }
  };
  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        setCopySuccess('¡Enlace copiado con éxito!');
      })
      .catch((err) => {
        console.error('Error al copiar el enlace:', err);
      });
  };
  const handleSubscribe = async (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
      alert('Error de reCAPTCHA, por favor intente nuevamente.');
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch('https://us-central1-alegra-club.cloudfunctions.net/verifyReCaptcha', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: recaptchaToken }),
      });

      const result = await response.json();

      if (result.success) {
        await setDoc(doc(db, 'Subscriptions', email), {
          email: email,
          createdAt: new Date()
        });
        alert('¡Gracias por suscribirse!');
        setEmail('');
      } else {
        alert('Error de reCAPTCHA, por favor intente nuevamente.');
      }
    } catch (error) {
      console.error('Error al suscribirse:', error);
      alert('Hubo un error al suscribirse. Por favor, intente nuevamente.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="home">
      <>
        <div className="home-logo-container">
          <Link to="/entradas">
            <img src={logo} alt="Logo" className="home-logo" />
            
          </Link>
        </div>
        {isAdmin ? (
          <div className="admin-title">
            <input
              type="text"
              value={content.title}
              onChange={(e) => handleInputChange({ target: { name: 'title', value: e.target.value } })}
              className="title-input"
            />
          </div>
        ) : (
          <h1>{content.title}</h1>
        )}
        <div className="home-images">
          {content.images.map((image, index) => (
            <div key={index} className="home-image-item">
              {isAdmin && (
                <button className="remove-button" onClick={() => handleRemoveImage(index)}>Eliminar</button>
              )}
              {isAdmin && !image && (
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(index, e)}
                />
              )}
              {image && (
                <img
                  src={imagePreviews[index] || image}
                  alt="Home"
                  className="home-image"
                />
              )}
            </div>
          ))}
        </div>
        {isAdmin && (
          <button onClick={handleAddImage} className="add-image-button">Agregar Imagen</button>
        )}
        <div className="home-social">
          {isAdmin ? (
            <>
              <input
                type="text"
                name="instagram"
                value={content.instagram}
                onChange={handleInputChange}
                placeholder="Instagram URL"
                className="social-input"
              />
              <input
                type="text"
                name="facebook"
                value={content.facebook}
                onChange={handleInputChange}
                placeholder="Facebook URL"
                className="social-input"
              />
              <button className="save-button" onClick={handleSaveChanges}>Guardar todo</button>
            </>
          ) : (
            <>
              <a href={content.instagram} target="_blank" rel="noopener noreferrer">
                <img src={instagramLogo} alt="Instagram" className="social-logo" />
              </a>
              {/* <a href={content.facebook} target="_blank" rel="noopener noreferrer">
                <img src={facebookLogo} alt="Facebook" className="social-logo" />
              </a> */}
            </>
          )}
        </div>
        <div className="subscription-form">
        <form onSubmit={handleSubscribe}>
        <div className="form-group">
          <label htmlFor="email">Recibirás información de eventos</label>
          <input
            type="email"
            placeholder="Ingresa tu email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="subscription-input"
          />
        </div>
        <button type="submit" className="subscription-button">Suscribirse</button>
      </form>
        </div>
        <div className="share-app-container">
          <button onClick={handleCopyLink} className="share-button">
            Compartir App
          </button>
          {copySuccess && <p className="copy-success">{copySuccess}</p>}
        </div>
      </>
    </div>
  );
};

export default Home;
