import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import Spinner from './Spinner';
import './Inventario.css';

const Inventario = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortType, setSortType] = useState(null); // Estado para tipo de ordenamiento de productos
  const [categorySortType, setCategorySortType] = useState(null); // Estado para tipo de ordenamiento de categorías
  const [sortedCategories, setSortedCategories] = useState([]); // Estado para categorías ordenadas

  const fetchProducts = useCallback(async () => {
    try {
      const productsCollection = await getDocs(collection(db, 'products'));
      const productsData = productsCollection.docs.map(doc => ({
        ...doc.data(), id: doc.id, stock: Number(doc.data().stock) || 0, costPrice: Number(doc.data().costPrice) || 0
      }));
      setProducts(productsData);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  useEffect(() => {
    const sortCategories = () => {
      const categoryMap = products.reduce((acc, product) => {
        const { category, stock, costPrice } = product;
        if (!acc[category]) acc[category] = { stock: 0, cost: 0 };
        acc[category].stock += stock;
        acc[category].cost += stock * costPrice;
        return acc;
      }, {});

      const categories = Object.keys(categoryMap).map(category => ({
        category,
        stock: categoryMap[category].stock,
        cost: categoryMap[category].cost
      }));

      const sorted = categorySortType === 'quantity'
        ? categories.sort((a, b) => b.stock - a.stock)
        : categories.sort((a, b) => b.cost - a.cost);

      setSortedCategories(sorted);
    };

    if (products.length > 0) {
      sortCategories();
    }
  }, [products, categorySortType]);

  const formatNumber = (number) => {
    if (typeof number !== 'number' || isNaN(number)) {
      return '0,00';
    }
    return number
      .toFixed(2) // Mantiene dos decimales
      .replace('.', ',') // Reemplaza el punto decimal por una coma
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agrega puntos como separadores de miles
  };
  
  
  

  const getPieData = () => {
    return {
      labels: products.map(product => product.model),
      datasets: [
        {
          data: sortType === 'cost'
            ? products.map(product => product.stock * product.costPrice)
            : products.map(product => product.stock),
          backgroundColor: [
            '#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40',
            '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'
          ],
        },
      ],
    };
  };

  const getCategoryPieData = () => {
    return {
      labels: sortedCategories.map(category => category.category),
      datasets: [
        {
          data: categorySortType === 'cost'
            ? sortedCategories.map(category => category.cost)
            : sortedCategories.map(category => category.stock),
          backgroundColor: [
            '#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40',
            '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'
          ],
        },
      ],
    };
  };

  const totalStock = products.reduce((total, product) => total + product.stock, 0);
  const totalCost = products.reduce((total, product) => total + (product.stock * product.costPrice), 0);

  const sortProducts = (type) => {
    const sorted = [...products];
    if (type === 'quantity') {
      sorted.sort((a, b) => ((b.stock / totalStock) * 100) - ((a.stock / totalStock) * 100));
    } else if (type === 'cost') {
      sorted.sort((a, b) => ((b.stock * b.costPrice / totalCost) * 100) - ((a.stock * a.costPrice / totalCost) * 100));
    }
    setProducts(sorted); // Actualizar el estado products
    setSortType(type);
  };

  if (loading) return <Spinner />;

  return (
    <div className="inventario">
      <h2>Inventario</h2>

      <div className="chart-section">
        <div className="chart-container">
          <h3>{sortType === 'cost' ? 'Precio de Costo' : 'Cantidad de Artículos'}</h3>
          <Pie data={getPieData()} />
        </div>
        <div className="sort-buttons">
          <button onClick={() => sortProducts('quantity')} className={`sort-btn ${sortType === 'quantity' ? 'active' : ''}`}>Ordenar por % Cantidad</button>
          <button onClick={() => sortProducts('cost')} className={`sort-btn ${sortType === 'cost' ? 'active' : ''}`}>Ordenar por % Costo</button>
        </div>
        <h3>Detalles del Inventario</h3>
        <div className="inventory-table-container">
          <table className="inventory-table">
            <thead>
              <tr>
                <th>Producto</th>
                <th>Cantidad</th>
                <th>% Cantidad</th>
                <th>Monto al Costo</th>
                <th>% Costo</th>
              </tr>
            </thead>
            <tbody>
              {products.map(product => {
                const quantityPercentage = ((product.stock / totalStock) * 100).toFixed(2);
                const costValue = product.stock * product.costPrice;
                const costPercentage = ((costValue / totalCost) * 100).toFixed(2);
                return (
                  <tr key={product.id}>
                    <td>{product.model}</td>
                    <td>{formatNumber(product.stock)}</td>
                    <td>{quantityPercentage}%</td>
                    <td>${formatNumber(costValue)}</td>
                    <td>{costPercentage}%</td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td>Total</td>
                <td>{formatNumber(totalStock)}</td>
                <td>100%</td>
                <td>${formatNumber(totalCost)}</td>
                <td>100%</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <div className="chart-section">
        <div className="chart-container">
          <h3>{categorySortType === 'cost' ? 'Costo por Categoría' : 'Cantidad por Categoría'}</h3>
          <Pie data={getCategoryPieData()} />
        </div>
        <div className="sort-buttons">
          <button onClick={() => setCategorySortType('quantity')} className={`sort-btn ${categorySortType === 'quantity' ? 'active' : ''}`}>Ordenar por % Cantidad</button>
          <button onClick={() => setCategorySortType('cost')} className={`sort-btn ${categorySortType === 'cost' ? 'active' : ''}`}>Ordenar por % Costo</button>
        </div>
        <h3>Detalles por Categoría</h3>
        <div className="inventory-table-container">
          <table className="inventory-table">
            <thead>
              <tr>
                <th>Categoría</th>
                <th>Cantidad</th>
                <th>% Cantidad</th>
                <th>Monto al Costo</th>
                <th>% Costo</th>
              </tr>
            </thead>
            <tbody>
              {sortedCategories.map(({ category, stock, cost }) => {
                const quantityPercentage = ((stock / totalStock) * 100).toFixed(2);
                const costPercentage = ((cost / totalCost) * 100).toFixed(2);
                return (
                  <tr key={category}>
                    <td>{category}</td>
                    <td>{formatNumber(stock)}</td>
                    <td>{quantityPercentage}%</td>
                    <td>${formatNumber(cost)}</td>
                    <td>{costPercentage}%</td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td>Total</td>
                <td>{formatNumber(totalStock)}</td>
                <td>100%</td>
                <td>${formatNumber(totalCost)}</td>
                <td>100%</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Inventario;
