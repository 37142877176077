import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Productos from './components/Products';
import OtherProducts from './components/OtherProducts';
import Navbar from './components/Navbar';
import Login from './components/Login';
import Cart from './components/Cart';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import AdminPanel from './components/AdminPanel';
import OrderStats from './components/OrderStats';
import Company from './components/Company';
import SubscriberList from './components/SubscriberList';
import MassEditor from './components/MassEditor';
import OrderList from './components/OrderList';
import DiscountConfig from './components/DiscountConfig';
import LocalSales from './components/LocalSales';
import StockManagement from './components/StockManagement'; 
import SuccessPage from './components/SuccessPage'; 
import QRScanner from './components/QRScanner';
import QRCodePage from './components/QRCodePage';
import WallStreetChart from './components/WallStreetChart';
import FreeTicketCreator from './components/FreeTicketCreator';
import QRCodeComponent from './components/QRCodeComponent';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import './App.css';

const App = () => {
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const removeFromCart = (id) => {
    setCart(cart.filter(item => item.id !== id));
  };

  const clearCart = () => {
    setCart([]);
  };

  const updateCartItemQuantity = (itemId, newQuantity) => {
    setCart(cart.map(item => item.id === itemId ? { ...item, quantity: newQuantity } : item));
  };

  const recaptchaFrontend = process.env.REACT_APP_RECAPTCHA_FRONTEND;
  const isAdmin = localStorage.getItem('isAdmin') === 'true';
  const userRole = localStorage.getItem('userRole');
  const websiteURL = 'https://alegra-club.web.app'; // Reemplaza con tu URL

  return (
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaFrontend}>
      <Router>
        <div className="App">
          <Navbar cart={cart} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/nosotros" element={<Company />} />
            <Route path="/contacto" element={<ContactForm />} />
            <Route path="/entradas" element={<Productos cart={cart} setCart={setCart} />} />
            <Route path="/consumo-interno" element={<OtherProducts cart={cart} setCart={setCart} />} />
            <Route path="/carrito" element={<Cart cart={cart} removeFromCart={removeFromCart} clearCart={clearCart} updateCartItemQuantity={updateCartItemQuantity} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/success" element={<SuccessPage />} /> 
            <Route path="/qr/:qrValue" element={<QRCodePage />} />
            {isAdmin && <Route path="/admin" element={<AdminPanel />} />}
            {isAdmin && <Route path="/subscriptores" element={<SubscriberList />} />}
            {isAdmin && <Route path="/editor-masivo" element={<MassEditor />} />}
            {isAdmin && <Route path="/historial-ventas" element={<OrderList />} />}
            {isAdmin && <Route path="/metricas" element={<OrderStats />} />}
            {isAdmin && <Route path="/grafico" element={<WallStreetChart />} />}
            {isAdmin && <Route path="/free" element={<FreeTicketCreator />} />}
            {(isAdmin || userRole === 'cashier' || userRole === 'qrScanner') && <Route path="/escanear-qr" element={<QRScanner userRole={userRole} />} />}
            {(isAdmin || userRole === 'cashier') && <Route path="/local" element={<LocalSales />} />}
            {isAdmin && <Route path="/configuracion-descuentos" element={<DiscountConfig />} />}
            {isAdmin && <Route path="/gestion-stock" element={<StockManagement />} />} 
            <Route path="/qr" element={<QRCodeComponent url={websiteURL} />} /> 
          </Routes>
          <Footer />
        </div>
      </Router>
    </GoogleReCaptchaProvider>
  );
};

export default App;





