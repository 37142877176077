import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../firebase';
import { collection, getDocs, doc, writeBatch } from 'firebase/firestore';
import Spinner from './Spinner';
import './StockManagement.css';
import Inventario from './Inventario';

const StockManagement = () => {
  const [products, setProducts] = useState([]);
  const [otherProducts, setOtherProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [confirmedProducts, setConfirmedProducts] = useState([]);

  const fetchProducts = useCallback(async () => {
    try {
      const productsCollection = await getDocs(collection(db, 'products'));
      const otherProductsCollection = await getDocs(collection(db, 'otherProducts'));

      const productsData = productsCollection.docs.map(doc => ({ ...doc.data(), id: doc.id, stock: Number(doc.data().stock) || 0 }));
      const otherProductsData = otherProductsCollection.docs.map(doc => ({ ...doc.data(), id: doc.id, stock: Number(doc.data().stock) || 0 }));

      setProducts(productsData);
      setOtherProducts(otherProductsData);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const addProductToStock = (product, quantity) => {
    const parsedQuantity = parseInt(quantity, 10);
    if (parsedQuantity <= 0 || isNaN(parsedQuantity)) {
      alert('Please enter a positive integer quantity');
      return;
    }
    setSelectedProducts(prevSelectedProducts => {
      const existingProductIndex = prevSelectedProducts.findIndex(item => item.id === product.id);
      if (existingProductIndex !== -1) {
        const updatedProducts = [...prevSelectedProducts];
        updatedProducts[existingProductIndex] = {
          ...updatedProducts[existingProductIndex],
          quantity: parsedQuantity
        };
        return updatedProducts;
      } else {
        return [...prevSelectedProducts, { ...product, quantity: parsedQuantity }];
      }
    });
  };

  const removeProductFromStock = (productId) => {
    setSelectedProducts(prevSelectedProducts => prevSelectedProducts.filter(item => item.id !== productId));
  };

  const handleStockUpdate = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const batch = writeBatch(db);
      selectedProducts.forEach((product) => {
        const collectionName = products.find(p => p.id === product.id) ? 'products' : 'otherProducts';
        const productRef = doc(db, collectionName, product.id);
        const newStock = product.stock + product.quantity;
        batch.update(productRef, { stock: newStock });
      });

      await batch.commit();
      alert('Stock updated successfully.');
      setConfirmedProducts(selectedProducts);
      setSelectedProducts([]);
    } catch (error) {
      console.error("Error updating stock:", error);
      alert('Error updating stock. Please try again.');
    } finally {
      setIsSubmitting(false);
      fetchProducts();
    }
  };

  const filteredProducts = searchTerm
    ? [...products, ...otherProducts].filter(product =>
      product.model.toLowerCase().includes(searchTerm.toLowerCase())
    ).slice(0, 5)
    : [];

  if (loading) return <Spinner />;

  return (
    <div className="stock-management">
      <h2 className="stock-management-title">Gestión de Inventario</h2>
      <input
        type="text"
        placeholder="Buscar producto"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="stock-management-search-input"
      />
      <div className="stock-management-products-list">
        {filteredProducts.map(product => (
          <div key={product.id} className="stock-management-product-item">
            <img src={product.images ? product.images[0] : ''} alt={product.model} className="stock-management-product-image" />
            <div className="stock-management-product-info">
              <span>{product.model} - ${product.price}</span>
              <span>Stock Actual: {product.stock}</span>
              <input
                type="number"
                placeholder="Cantidad"
                min="1"
                onChange={(e) => addProductToStock(product, e.target.value)}
                className="stock-management-quantity-input"
              />
            </div>
          </div>
        ))}
      </div>
      <h3 className="stock-management-selected-title">Productos Seleccionados</h3>
      <ul className="stock-management-selected-list">
        {selectedProducts.map(product => (
          <li key={product.id} className="stock-management-selected-item">
            {product.model} - {product.quantity} unidades
            <button onClick={() => removeProductFromStock(product.id)} className="stock-management-remove-btn">Eliminar</button>
          </li>
        ))}
      </ul>
      <div className="stock-management-submit-container">
        <button
          onClick={handleStockUpdate}
          className={`stock-management-submit-btn ${isSubmitting ? 'stock-management-submit-btn-disabled' : ''}`}
          disabled={isSubmitting}
        >
          Agregar Stock
        </button>
        {isSubmitting && <Spinner />}
      </div>
      {confirmedProducts.length > 0 && (
        <>
          <h3 className="stock-management-summary-title">Resumen de Productos Agregados</h3>
          <ul className="stock-management-summary-list">
            {confirmedProducts.map(product => (
              <li key={product.id} className="stock-management-summary-item">
                <img src={product.images ? product.images[0] : ''} alt={product.model} className="stock-management-summary-image" />
                <div className="stock-management-summary-info">
                  <span>{product.model} - {product.quantity} unidades</span>
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
      <Inventario />
    </div>
  );
};

export default StockManagement;
