import React from 'react';
import './ProductDetailModal.css';

const ProductDetailModal = ({ product, onClose }) => {
  return (
    <div className="modal-overlay-container">
      <div className="modal-content-container product-detail-modal">
        <h3>{product.model}</h3>
        {product.images && product.images.map((image, index) => (
          <img key={index} src={image} alt={product.model} className="product-detail-image" />
        ))}
        <p><strong>Precio:</strong> ${product.price.toFixed(2)}</p>
        <p><strong>Categoría:</strong> {product.category}</p>
        <p><strong>Descripción:</strong> {product.description}</p>
        <p><strong>Stock disponible:</strong> {product.stock}</p>
        <button onClick={onClose} className="close-button">Cerrar</button>
      </div>
    </div>
  );
};

export default ProductDetailModal;

