import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';
import logo from '../Logos/AlegraLogoBlanco.png';

const Navbar = ({ cart }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  const isAdmin = localStorage.getItem('isAdmin') === 'true';
  const userRole = localStorage.getItem('userRole'); // Obtener el rol del usuario
  const menuRef = useRef(null);
  const hamburgerRef = useRef(null);

  const formatNumber = (number) => {
    if (typeof number !== 'number' || isNaN(number)) {
      return '0,00';
    }
    return number
      .toFixed(2) // Mantiene dos decimales
      .replace('.', ',') // Reemplaza el punto decimal por una coma
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agrega puntos como separadores de miles
  };

  useEffect(() => {
    const logoImage = document.querySelector('.logo img');
    if (logoImage) {
      logoImage.classList.add('loaded');
    }

    const handleClickOutside = (event) => {
      if (isOpen && 
          menuRef.current && 
          !menuRef.current.contains(event.target) &&
          !hamburgerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('userRole');
    navigate('/login');
  };

  const totalQuantity = cart.reduce((sum, item) => sum + item.quantity, 0);
  const totalAmount = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Allegra Logo" />
          </Link>
        </div>
        <ul className={`nav-links ${isOpen ? 'open' : ''}`} ref={menuRef}>
          <li><Link to="/entradas" onClick={toggleMenu}>Entradas</Link></li>
          <li><Link to="/consumo-interno" onClick={toggleMenu}>Consumo interno</Link></li>
          <li><Link to="/nosotros" onClick={toggleMenu}>Nosotros</Link></li>
          <li><Link to="/contacto" onClick={toggleMenu}>Contacto</Link></li>
          <li>
            <Link to="/carrito" onClick={toggleMenu}>
              Carrito {totalQuantity > 0 && (
                <span className="cart-quantity">
                  ${formatNumber(totalAmount)}
                </span>
              )}
            </Link>
          </li>
          {isAdmin && (
            <li><Link to="/admin" onClick={toggleMenu}>Admin</Link></li>
          )}
          {userRole === 'cashier' && (
            <li><Link to="/local" onClick={toggleMenu}>Local</Link></li>
          )}
          {userRole === 'qrScanner' && (
            <li><Link to="/escanear-qr" onClick={toggleMenu}>Escanear QR</Link></li>
          )}
          {!isAuthenticated ? (
            <li><Link to="/login" onClick={toggleMenu}>Login</Link></li>
          ) : (
            <li><button onClick={handleLogout}>Logout</button></li>
          )}
        </ul>
        <div className="hamburger" onClick={toggleMenu} ref={hamburgerRef}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
