import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { QRCode } from 'react-qr-code';
import emailjs from 'emailjs-com';
import './SuccessPage.css';

const SuccessPage = () => {
  const [searchParams] = useSearchParams();
  const [orderData, setOrderData] = useState(null);

  // Función para crear el hash SHA-256 usando la API SubtleCrypto
  const createSHA256Hash = async (input) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(input);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  };

  useEffect(() => {
    const fetchOrderData = async () => {
      const externalReference = searchParams.get('external_reference');
      if (externalReference) {
        try {
          const orderRef = doc(db, 'compras_mercadopago', externalReference);
          const orderDoc = await getDoc(orderRef);
          if (orderDoc.exists()) {
            const orderData = orderDoc.data();

            // Verificar si el correo ya ha sido enviado
            if (!orderData.emailSent) {
              // Generar SHA-256 hash para cada ítem usando SubtleCrypto
              const updatedCartItems = await Promise.all(orderData.cartItems.map(async item => {
                const sha256Hash = await createSHA256Hash(`${item.id}-${orderData.orderNumber}`);
                return {
                  ...item,
                  qrValue: sha256Hash,
                };
              }));

              // Actualizar Firestore con los nuevos valores de QR y emailSent
              await updateDoc(orderRef, { cartItems: updatedCartItems, emailSent: true });

              setOrderData({ ...orderData, cartItems: updatedCartItems });

              const templateParams = {
                order_number: orderData.orderNumber || 'N/A',
                order_details: updatedCartItems.map(item => `${item.model} - Cantidad: ${item.quantity}`).join('<br/>'),
                qr_codes: updatedCartItems.map(item => {
                  const qrProductLink = `https://alegra-club.web.app/qr/${encodeURIComponent(item.qrValue)}`;
                  return `<a href="${qrProductLink}">Ver QR para ${item.model}</a>`;
                }).join('<br/>'),
                to_email: orderData.email || 'email_no_provided@example.com',
                from_name: "Alegra Club"
              };

              await emailjs.send(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                templateParams,
                process.env.REACT_APP_EMAILJS_USER_ID
              );

              console.log('Email sent successfully');
            } else {
              setOrderData(orderData); // No se envía el correo, pero se configura el estado
              // console.log('Email already sent, skipping email sending.');
            }
          }
        } catch (error) {
          console.error('Error fetching order data:', error);
        }
      }
    };

    fetchOrderData();
  }, [searchParams]);

  return (
    <div className="success-page">
      <div className="success-message">
        <h2>¡Gracias por tu compra!</h2>
        <p>Este es tu QR, también te lo hemos enviado por email.</p>
      </div>
      <div className="qr-container">
        {orderData && orderData.cartItems.map(item => (
          <div key={item.id} className="qr-item">
            <QRCode value={item.qrValue} size={256} />
            <p>Cantidad: {item.quantity}</p>
            <p>{item.model}</p> 
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuccessPage;
