import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../firebase';
import { collection, query, getDocs, orderBy, doc, updateDoc } from 'firebase/firestore';
import emailjs from 'emailjs-com';
import './OrderList.css';

const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [visibleOrders, setVisibleOrders] = useState(10);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const q = query(collection(db, 'compras_mercadopago'), orderBy('date', 'desc'));
        const querySnapshot = await getDocs(q);
        const ordersList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setOrders(ordersList);
        setFilteredOrders(ordersList);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);

  const filterOrders = useCallback(() => {
    let filtered = orders;

    if (startDate && endDate) {
      filtered = filtered.filter(order => {
        const orderDate = order.date.toDate();
        return orderDate >= new Date(startDate) && orderDate <= new Date(endDate);
      });
    }

    if (searchQuery) {
      filtered = filtered.filter(order =>
        order.orderNumber.toString().includes(searchQuery) ||
        order.nombre.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredOrders(filtered);
  }, [orders, startDate, endDate, searchQuery]);

  useEffect(() => {
    filterOrders();
  }, [filterOrders]);

  const toggleOrderDetails = (orderId) => {
    setSelectedOrder(selectedOrder === orderId ? null : orderId);
  };

  const loadMoreOrders = () => {
    setVisibleOrders(prevVisibleOrders => prevVisibleOrders + 10);
  };

  // Function to create SHA-256 hash
  const createSHA256Hash = async (input) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(input);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  };

  // Function to generate QR values and send email
  const handleGenerateAndSendQR = async (order) => {
    try {
      const orderRef = doc(db, 'compras_mercadopago', order.id);
      const updatedCartItems = await Promise.all(order.cartItems.map(async (item) => {
        if (!item.qrValue) {
          const sha256Hash = await createSHA256Hash(`${item.id}-${order.orderNumber}`);
          return {
            ...item,
            qrValue: sha256Hash,
          };
        }
        return item;
      }));

      // Update Firestore with new QR values
      await updateDoc(orderRef, { cartItems: updatedCartItems, emailSent: true });

      const templateParams = {
        order_number: order.orderNumber || 'N/A',
        order_details: updatedCartItems.map(item => `${item.model} - Cantidad: ${item.quantity}`).join('<br/>'),
        qr_codes: updatedCartItems.map(item => {
          const qrProductLink = `https://alegra-club.web.app/qr/${encodeURIComponent(item.qrValue)}`;
          return `<a href="${qrProductLink}">Ver QR para ${item.model}</a>`;
        }).join('<br/>'),
        to_email: order.email || 'email_no_provided@example.com',
        from_name: "Alegra Club"
      };

      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      );

      console.log('Email sent successfully');
      // Optionally update the local state
      setFilteredOrders((prevOrders) =>
        prevOrders.map((o) => (o.id === order.id ? { ...o, cartItems: updatedCartItems } : o))
      );
    } catch (error) {
      console.error('Error generating and sending QR:', error);
    }
  };

  return (
    <div className="orderList-container">
      <h2>TICKETS</h2>
      <div className="orderList-filters">
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          placeholder="Fecha de inicio"
          className="orderList-filterInput"
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          placeholder="Fecha de fin"
          className="orderList-filterInput"
        />
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Buscar"
          className="orderList-filterInput"
        />
      </div>
      <ul className="orderList-ul">
        {filteredOrders.slice(0, visibleOrders).map(order => (
          <li key={order.id} className="orderList-item">
            <div className="orderList-summary">
              <span>Número de Orden: {order.orderNumber}</span>
              <span>Nombre: {order.nombre}</span>
              <span>Fecha de compra: {order.date.toDate().toLocaleString()}</span>
              <button onClick={() => toggleOrderDetails(order.id)}>
                {selectedOrder === order.id ? 'Ocultar Detalles' : 'Ver Detalles'}
              </button>
            </div>
            {selectedOrder === order.id && (
              <div className="orderList-details">
                <h4>Detalles de la Orden</h4>
                {order.cartItems.map((item, index) => (
                  <div
                    key={index}
                    className={`orderList-itemContainer ${item.isUsed ? 'orderList-used' : 'orderList-active'}`}
                    style={{
                      borderColor: item.isUsed ? 'red' : 'green',
                      opacity: order.isActive ? '1' : '0.5'
                    }}
                  >
                    <p>Producto: {item.model}</p>
                    <p>Cantidad: {item.quantity}</p>
                    <p>Estado: {item.isUsed ? 'Usado' : 'Vigente'}</p>
                    <p>Fecha ingreso: {item.usedTimestamp ? item.usedTimestamp : 'Aún no ha ingresado'}</p>
                    <p>Referencia externa: {order.id}</p>
                    {!item.qrValue && (
                      <button onClick={() => handleGenerateAndSendQR(order)}>
                        Generar y Enviar QR
                      </button>
                    )}
                  </div>
                ))}
              </div>
            )}
          </li>
        ))}
      </ul>
      {visibleOrders < filteredOrders.length && (
        <button onClick={loadMoreOrders} className="orderList-loadMoreButton">Cargar más</button>
      )}
    </div>
  );
};

export default OrderList;
