import React, { useState, useEffect } from 'react';
import { pinFileToIPFS } from '../pinata';

const ProductForm = ({ product, onSubmit, onCancel, categories, setCategories }) => {
  const [model, setModel] = useState(product?.model || '');
  const [price, setPrice] = useState(product?.price || '');
  const [description, setDescription] = useState(product?.description || '');
  const [category, setCategory] = useState(product?.category || '');
  const [newCategory, setNewCategory] = useState('');
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState(product?.images || []);
  const [loading, setLoading] = useState(false);
  const [stock, setStock] = useState(product?.stock || 0);

  useEffect(() => {
    if (files.length > 0) {
      const fileReaders = [];
      files.forEach((file, index) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviews(prevPreviews => {
            const newPreviews = [...prevPreviews];
            newPreviews[index] = reader.result;
            return newPreviews;
          });
        };
        reader.readAsDataURL(file);
        fileReaders.push(reader);
      });
    }
  }, [files]);

  const handleRemoveImage = (index) => {
    setPreviews(prevPreviews => prevPreviews.filter((_, i) => i !== index));
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let imageUrls = [];
    if (files.length > 0) {
      try {
        const uploadPromises = files.map(file => pinFileToIPFS(file));
        imageUrls = await Promise.all(uploadPromises);
      } catch (error) {
        console.error('Error uploading images to Pinata:', error);
        alert('Error uploading images, please try again.');
        setLoading(false);
        return;
      }
    } else {
      imageUrls = previews;
    }

    const finalCategory = newCategory || category;

    if (newCategory && !categories.includes(newCategory)) {
      setCategories([...categories, newCategory]);
    }

    onSubmit({
      model,
      images: imageUrls,
      price: parseFloat(price),
      description,
      category: finalCategory,
      stock: parseInt(stock)
    });
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="product-form-container">
      <h3>{product ? 'Editar Producto' : 'Agregar Nuevo Producto'}</h3>
      <div className="form-group-container">
        <label htmlFor="model" className="form-label">Modelo</label>
        <input
          type="text"
          id="model"
          value={model}
          onChange={(e) => setModel(e.target.value)}
          required
          className="form-input"
        />
      </div>
      <div className="form-group-container">
        <label htmlFor="images" className="form-label">Imágenes (hasta 3)</label>
        <input type="file" onChange={(e) => setFiles([...files, ...e.target.files])} accept=".jpg,.png" multiple className="form-input" />
        <div className="image-preview-container">
          {previews.map((preview, index) => (
            <div key={index} className="image-preview-wrapper">
              <img src={preview} alt={`Preview ${index + 1}`} className="image-preview" />
              <button type="button" onClick={() => handleRemoveImage(index)} className="remove-image-button">x</button>
            </div>
          ))}
        </div>
      </div>
      <div className="form-group-container">
        <label htmlFor="price" className="form-label">Precio</label>
        <input
          type="number"
          id="price"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          required
          className="form-input"
        />
      </div>
      <div className="form-group-container">
        <label htmlFor="stock" className="form-label">Stock</label>
        <input
          type="number"
          id="stock"
          value={stock}
          onChange={(e) => setStock(e.target.value)}
          required
          className="form-input"
        />
      </div>
      <div className="form-group-container">
        <label htmlFor="description" className="form-label">Descripción</label>
        <textarea
          id="description"
          maxLength={300}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          className="form-textarea"
        />
      </div>
      <div className="form-group-container">
        <label htmlFor="category" className="form-label">Categoría</label>
        <select
          id="category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          required
          className="form-select"
        >
          <option value="">Seleccione una categoría</option>
          {categories.map(cat => (
            <option key={cat} value={cat}>{cat}</option>
          ))}
          <option value="new">Nueva Categoría</option>
        </select>
      </div>
      {category === 'new' && (
        <div className="form-group-container">
          <label htmlFor="newCategory" className="form-label">Nueva Categoría</label>
          <input
            type="text"
            id="newCategory"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            required
            className="form-input"
          />
        </div>
      )}
      <div className="form-actions-container">
        <button type="submit" disabled={loading} className="form-action-button submit">
          Guardar
        </button>
        <button type="button" onClick={onCancel} disabled={loading} className="form-action-button cancel">Cancelar</button>
      </div>
    </form>
  );
};

export default ProductForm;
