import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCRH75NeD2x7mOKiAyZ2SdLTJzidYCePEs",
  authDomain: "alegra-club.firebaseapp.com",
  projectId: "alegra-club",
  storageBucket: "alegra-club.appspot.com",
  messagingSenderId: "852577853298",
  appId: "1:852577853298:web:f203f7fb9df1be8e5287eb",
  measurementId: "G-MC0F171WVT"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

export { auth, db,functions };
